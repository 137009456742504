import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, User, Tag } from 'lucide-react';

const Blog = () => {
  const blogPosts = [
    {
      title: "7 Essential Tips for Efficient Corporate Group Travel in India",
      excerpt: "Discover how to optimize your company's group travel within India with these expert tips that save time and money. From navigating diverse transportation options to managing cultural considerations, we provide comprehensive strategies for seamless business trips across the subcontinent. Learn how to leverage technology, local partnerships, and smart booking techniques to enhance your corporate travel experience.",
      date: "June 15, 2024",
      author: "Priya Sharma",
      category: "Travel Tips"
    },
    {
      title: "The Evolution of Business Travel in Post-Pandemic India",
      excerpt: "Explore how corporate travel in India is adapting to new challenges in the wake of global changes. We delve into emerging trends, innovative technologies, and shifting priorities shaping the industry. From the rise of bleisure travel to the implementation of contactless solutions, discover how Indian businesses are reimagining travel policies and practices to ensure safety, efficiency, and employee satisfaction in this new era.",
      date: "May 28, 2024",
      author: "Rajesh Patel",
      category: "Industry Insights"
    },
    {
      title: "Maximizing Productivity During Business Trips Across India",
      excerpt: "Uncover strategies to keep your team efficient and focused while traveling for work in India's dynamic business landscape. From time management techniques tailored to different Indian cities to leveraging local coworking spaces, we provide actionable insights to boost your team's output on the go. Learn how to balance productive work sessions with cultural experiences, ensuring your team returns inspired and accomplished.",
      date: "May 10, 2024",
      author: "Anita Desai",
      category: "Productivity"
    },
    {
      title: "Sustainable Corporate Travel in India: Balancing Business and Environment",
      excerpt: "Discover how your company can reduce its carbon footprint without compromising on travel needs in the Indian context. We discuss eco-friendly practices, sustainable travel options, and green initiatives specifically tailored for businesses operating in India. From choosing environmentally responsible accommodations to supporting local conservation efforts, learn how to align your corporate travel with sustainability goals while navigating India's unique environmental challenges.",
      date: "April 22, 2024",
      author: "Arjun Mehta",
      category: "Sustainability"
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <section className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 py-20">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-4xl mx-auto px-4"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold text-center mb-8 text-white"
        >
          TRIPOSCA Blog
        </motion.h1>

        <motion.p
          variants={itemVariants}
          className="text-center text-lg mb-12 text-gray-300"
        >
          Stay updated with the latest trends and insights in corporate travel
        </motion.p>

        <motion.div 
          variants={containerVariants}
          className="space-y-12"
        >
          {blogPosts.map((post, index) => (
            <motion.article
              key={index}
              variants={itemVariants}
              className="bg-gray-900 rounded-xl shadow-2xl overflow-hidden hover:shadow-3xl transition-shadow duration-300 border-l-4 border-blue-500"
            >
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <span className="text-blue-400 flex items-center">
                    <Tag size={16} className="mr-2" />
                    {post.category}
                  </span>
                  <span className="text-gray-500 flex items-center">
                    <Calendar size={16} className="mr-2" />
                    {post.date}
                  </span>
                </div>
                <h2 className="text-2xl font-semibold mb-4 text-white">{post.title}</h2>
                <p className="text-gray-400 mb-4">{post.excerpt}</p>
                <div className="flex items-center text-sm text-gray-500">
                  <User size={16} className="mr-2" />
                  {post.author}
                </div>
              </div>
            </motion.article>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Blog;