import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const IntroAnimation = ({ onComplete }) => {
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    // This useEffect hook runs when the component mounts or when onComplete changes
    
    // Set a timer to hide the intro and call onComplete after 1700 milliseconds
    const timer = setTimeout(() => {
      setShowIntro(false);  // Hide the intro
      onComplete();         // Call the onComplete function passed as a prop
    }, 1700); // 1700ms (1.7 seconds) delay, increased to allow for new animations

    // Cleanup function to clear the timer if the component unmounts before the timer finishes
    return () => clearTimeout(timer);
  }, [onComplete]); // This effect depends on onComplete, so it will re-run if onComplete changes

  const letterVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        type: "spring",
        damping: 12,
        stiffness: 200
      }
    },
    exit: { 
      opacity: 0, 
      y: -50, 
      transition: { 
        duration: 0.5 
      } 
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    },
    exit: { 
      opacity: 0, 
      transition: { 
        duration: 0.5,
        when: "afterChildren"
      } 
    }
  };

  const Star = ({ x, y }) => (
    <motion.circle
      cx={x}
      cy={y}
      r={1}
      fill="white"
      initial={{ opacity: 0.1 }}
      animate={{ opacity: [0.1, 1, 0.1] }}
      transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
    />
  );

  const ShootingStar = () => (
    <motion.line
      x1="0"
      y1="0"
      x2="100"
      y2="100"
      stroke="white"
      strokeWidth="2"
      initial={{ pathLength: 0, opacity: 1 }}
      animate={{ pathLength: 1, opacity: [0, 1, 0] }}
      transition={{ duration: 1, repeat: Infinity, repeatDelay: Math.random() * 5 }}
    />
  );

  const Cloud = ({ x, y }) => (
    <motion.path
      d="M25,60 a20,20 0 0,1 0,-40 a20,20 0 0,1 40,0 a20,20 0 0,1 0,40 z"
      fill="rgba(255, 255, 255, 0.3)"
      initial={{ x, y }}
      animate={{ x: x + 50 }}
      transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
    />
  );

  return (
    <AnimatePresence>
      {showIntro && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 z-50 overflow-hidden"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={containerVariants}
        >
          <svg className="absolute inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <radialGradient id="map-gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="0%" stopColor="#4B0082" stopOpacity="0.5" />
                <stop offset="100%" stopColor="#000080" stopOpacity="0.8" />
              </radialGradient>
            </defs>
            
            {/* Map background */}
            <motion.circle
              cx="50%"
              cy="50%"
              r="30%"
              fill="url(#map-gradient)"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 2 }}
            />

            {/* Stars */}
            {[...Array(50)].map((_, i) => (
              <Star key={i} x={`${Math.random() * 100}%`} y={`${Math.random() * 100}%`} />
            ))}

            {/* Shooting stars */}
            {[...Array(3)].map((_, i) => (
              <ShootingStar key={i} />
            ))}

            {/* Clouds */}
            <Cloud x={100} y={50} />
            <Cloud x={300} y={100} />
            <Cloud x={500} y={75} />
          </svg>

          <div className="relative z-10">
            {"TRIPOSCA".split('').map((letter, index) => (
              <motion.span
                key={index}
                variants={letterVariants}
                className="text-6xl md:text-8xl font-bold text-white inline-block"
              >
                {letter}
              </motion.span>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default IntroAnimation;