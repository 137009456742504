import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is the minimum group size for corporate bookings?",
      answer: "Our corporate travel services are tailored for groups of 20 or more employees. This allows us to provide the best rates and most efficient travel arrangements for your team."
    },
    {
      question: "How far in advance should we book our corporate travel?",
      answer: "We recommend booking at least 3-6 months in advance for large group travel. This ensures better availability and rates. However, we understand that business needs can arise suddenly, and we're equipped to handle last-minute bookings as well."
    },
    {
      question: "Do you offer travel insurance for corporate groups?",
      answer: "Yes, we offer comprehensive travel insurance options tailored for corporate groups. This includes coverage for trip cancellations, medical emergencies, and other travel-related incidents."
    },
    {
      question: "Can you accommodate special dietary requirements for group meals?",
      answer: "Absolutely. We work closely with airlines and hotels to accommodate all dietary requirements, including vegetarian, vegan, kosher, halal, and allergy-specific meals."
    },
    {
      question: "How do you handle changes or cancellations to our booking?",
      answer: "We understand that plans can change. Our flexible booking policies allow for changes and cancellations, often without penalties if done within a specified timeframe. Our 24/7 support team is always available to assist with any modifications to your travel plans."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <section className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 py-20">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-4xl mx-auto px-4"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold text-center mb-8 text-white"
        >
          Frequently Asked Questions
        </motion.h1>

        <motion.div variants={containerVariants} className="space-y-4">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="bg-gray-900 rounded-xl shadow-2xl overflow-hidden"
            >
              <motion.button
                className="w-full p-6 flex justify-between items-center text-left"
                onClick={() => setActiveIndex(activeIndex === index ? null : index)}
              >
                <span className="text-lg font-semibold text-white">{faq.question}</span>
                {activeIndex === index ? (
                  <ChevronUp className="text-blue-500" />
                ) : (
                  <ChevronDown className="text-blue-500" />
                )}
              </motion.button>
              <AnimatePresence>
                {activeIndex === index && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    <div className="p-6 pt-0 text-gray-400">
                      {faq.answer}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default FAQ;