import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { User, Mail, MessageSquare, Send } from 'lucide-react';

const Contact = () => {
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
    alert("Message Sent!");
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  const InputField = ({ icon: Icon, ...props }) => (
    <div className="relative">
      <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
      <input
        {...props}
        className="w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-10 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
    </div>
  );

  return (
    <section className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 py-20">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-4xl mx-auto px-4"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold text-center mb-8 text-white"
        >
          Contact Us
        </motion.h1>
        <motion.p
          variants={itemVariants}
          className="text-center text-gray-300 mb-12 max-w-2xl mx-auto"
        >
          Have questions or need assistance? We're here to help! Fill out the form below, and our team will get back to you as soon as possible.
        </motion.p>
        
        <motion.div
          variants={itemVariants}
          className="bg-gray-900 p-8 rounded-xl shadow-2xl"
        >
          <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
            <motion.div variants={itemVariants}>
              <label htmlFor="name" className="block text-sm font-medium text-gray-400 mb-1">Name</label>
              <InputField
                icon={User}
                type="text"
                id="name"
                name="name"
                placeholder="Your First & Last Name"
                required
              />
            </motion.div>
            <motion.div variants={itemVariants}>
              <label htmlFor="email" className="block text-sm font-medium text-gray-400 mb-1">Email</label>
              <InputField
                icon={Mail}
                type="email"
                id="email"
                name="email"
                placeholder="ajay@example.com"
                required
              />
            </motion.div>
            <motion.div variants={itemVariants}>
              <label htmlFor="message" className="block text-sm font-medium text-gray-400 mb-1">Message</label>
              <div className="relative">
                <MessageSquare className="absolute left-3 top-3 text-gray-400" size={18} />
                <textarea
                  id="message"
                  name="message"
                  placeholder="Your message here..."
                  className="w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-10 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent min-h-[150px]"
                  required
                />
              </div>
            </motion.div>
            <motion.div variants={itemVariants}>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-4 rounded-lg font-semibold shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 flex items-center justify-center"
              >
                <Send className="mr-2" size={18} />
                Send Message
              </motion.button>
            </motion.div>
          </form>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Contact;