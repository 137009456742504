import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Plane, Calendar, Users, MapPin, Briefcase, IndianRupee } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const FlightSearchForm = () => {
  const [formData, setFormData] = useState({
    tripType: 'roundTrip',
    from: '',
    to: '',
    departDate: null,
    returnDate: null,
    passengers: 1,
    class: 'economy',
    priceRange: [0, 200000],
    flexibleDates: false,
    preferredAirline: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleDateChange = (date, name) => {
    setFormData(prev => ({ ...prev, [name]: date }));
  };

  const handlePriceRangeChange = (e, index) => {
    const newPriceRange = [...formData.priceRange];
    newPriceRange[index] = parseInt(e.target.value);
    setFormData(prev => ({ ...prev, priceRange: newPriceRange }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Handle form submission logic here
  };

  const formVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const inputVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <div className="min-h-screen pt-20 px-4 bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900">
      <motion.form
        variants={formVariants}
        initial="hidden"
        animate="visible"
        onSubmit={handleSubmit}
        className="max-w-4xl mx-auto bg-gray-900 bg-opacity-80 rounded-xl shadow-2xl p-6 space-y-6"
      >
        <motion.h2 
          className="text-3xl font-bold text-white text-center mb-6"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          Discover Your Next Journey
        </motion.h2>
        
        <div className="flex flex-wrap justify-center space-x-4 mb-6">
          {['roundTrip', 'oneWay', 'multiCity'].map((type) => (
            <motion.label 
              key={type} 
              className="flex items-center space-x-2 cursor-pointer text-gray-300 mb-2"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <input
                type="radio"
                name="tripType"
                value={type}
                checked={formData.tripType === type}
                onChange={handleChange}
                className="form-radio text-blue-500"
              />
              <span className="capitalize">{type.replace(/([A-Z])/g, ' $1').trim()}</span>
            </motion.label>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <motion.div variants={inputVariants} className="relative">
            <MapPin className="absolute left-3 top-3 h-5 w-5 text-blue-400" />
            <input
              type="text"
              name="from"
              value={formData.from}
              onChange={handleChange}
              className="w-full pl-10 pr-3 py-2 rounded-md border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="From"
            />
          </motion.div>
          <motion.div variants={inputVariants} className="relative">
            <MapPin className="absolute left-3 top-3 h-5 w-5 text-blue-400" />
            <input
              type="text"
              name="to"
              value={formData.to}
              onChange={handleChange}
              className="w-full pl-10 pr-3 py-2 rounded-md border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="To"
            />
          </motion.div>
          <motion.div variants={inputVariants} className="relative">
            <Calendar className="absolute left-3 top-3 h-5 w-5 text-blue-400" />
            <DatePicker
              selected={formData.departDate}
              onChange={(date) => handleDateChange(date, 'departDate')}
              className="w-full pl-10 pr-3 py-2 rounded-md border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholderText="Depart Date"
            />
          </motion.div>
          {formData.tripType === 'roundTrip' && (
            <motion.div variants={inputVariants} className="relative">
              <Calendar className="absolute left-3 top-3 h-5 w-5 text-blue-400" />
              <DatePicker
                selected={formData.returnDate}
                onChange={(date) => handleDateChange(date, 'returnDate')}
                className="w-full pl-10 pr-3 py-2 rounded-md border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholderText="Return Date"
              />
            </motion.div>
          )}
          <motion.div variants={inputVariants} className="relative">
            <Users className="absolute left-3 top-3 h-5 w-5 text-blue-400" />
            <select
              name="passengers"
              value={formData.passengers}
              onChange={handleChange}
              className="w-full pl-10 pr-3 py-2 rounded-md border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {[1, 2, 3, 4, 5, 6].map(num => (
                <option key={num} value={num}>{num} {num === 1 ? 'Passenger' : 'Passengers'}</option>
              ))}
            </select>
          </motion.div>
          <motion.div variants={inputVariants} className="relative">
            <Briefcase className="absolute left-3 top-3 h-5 w-5 text-blue-400" />
            <select
              name="class"
              value={formData.class}
              onChange={handleChange}
              className="w-full pl-10 pr-3 py-2 rounded-md border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="economy">Economy</option>
              <option value="premium">Premium Economy</option>
              <option value="business">Business</option>
              <option value="first">First Class</option>
            </select>
          </motion.div>
        </div>

        <motion.div variants={inputVariants} className="space-y-2">
          <label className="block text-gray-300 text-sm font-bold mb-2">Price Range (₹)</label>
          <div className="flex items-center space-x-4">
            <IndianRupee className="h-5 w-5 text-blue-400" />
            <input
              type="range"
              min="0"
              max="200000"
              value={formData.priceRange[0]}
              onChange={(e) => handlePriceRangeChange(e, 0)}
              className="w-full"
            />
            <input
              type="range"
              min="0"
              max="200000"
              value={formData.priceRange[1]}
              onChange={(e) => handlePriceRangeChange(e, 1)}
              className="w-full"
            />
          </div>
          <div className="flex justify-between mt-2 text-gray-300">
            <span>₹{formData.priceRange[0].toLocaleString('en-IN')}</span>
            <span>₹{formData.priceRange[1].toLocaleString('en-IN')}</span>
          </div>
        </motion.div>

        <motion.div variants={inputVariants} className="relative">
          <Plane className="absolute left-3 top-3 h-5 w-5 text-blue-400" />
          <input
            type="text"
            name="preferredAirline"
            value={formData.preferredAirline}
            onChange={handleChange}
            className="w-full pl-10 pr-3 py-2 rounded-md border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Preferred Airline (optional)"
          />
        </motion.div>

        <motion.div variants={inputVariants}>
          <label className="flex items-center space-x-2 cursor-pointer text-gray-300">
            <input
              type="checkbox"
              name="flexibleDates"
              checked={formData.flexibleDates}
              onChange={handleChange}
              className="form-checkbox text-blue-500"
            />
            <span>Flexible Dates (+/- 3 days)</span>
          </label>
        </motion.div>

        <motion.button
          whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(59 130 246)" }}
          whileTap={{ scale: 0.95 }}
          className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white py-3 px-4 rounded-md transition duration-300 flex items-center justify-center space-x-2 shadow-md"
          type="submit"
        >
          <Plane size={20} />
          <span>Find Your Perfect Flight</span>
        </motion.button>
      </motion.form>
    </div>
  );
};

export default FlightSearchForm;