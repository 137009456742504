import React from 'react';
import { motion } from 'framer-motion';

const DynamicBackground = () => {
  const starCount = 100;
  const shootingStarCount = 5;

  return (
    <svg
      className="absolute inset-0 w-full h-full"
      preserveAspectRatio="xMidYMid slice"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <radialGradient id="galaxy-gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
          <stop offset="0%" stopColor="#667eea" stopOpacity="0.3" />
          <stop offset="100%" stopColor="#764ba2" stopOpacity="0.1" />
        </radialGradient>
      </defs>
      <rect width="100%" height="100%" fill="url(#galaxy-gradient)" />
      
      {/* Twinkling stars */}
      {[...Array(starCount)].map((_, i) => (
        <motion.circle
          key={`star-${i}`}
          r={Math.random() * 1.5 + 0.5}
          cx={`${Math.random() * 100}%`}
          cy={`${Math.random() * 100}%`}
          fill="white"
          initial={{ opacity: 0 }}
          animate={{
            opacity: [0, 1, 0],
            scale: [0, 1, 0],
          }}
          transition={{
            duration: Math.random() * 2 + 1,
            repeat: Infinity,
            repeatType: 'loop',
            ease: 'easeInOut',
            delay: Math.random() * 2,
          }}
        />
      ))}

      {/* Shooting stars */}
      {[...Array(shootingStarCount)].map((_, i) => (
        <motion.line
          key={`shooting-star-${i}`}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
          stroke="white"
          strokeWidth="2"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{
            pathLength: [0, 1],
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatDelay: Math.random() * 5 + 5,
            ease: "easeOut",
          }}
          style={{
            transform: `translate(${Math.random() * 100}%, ${Math.random() * 100}%) rotate(${Math.random() * 360}deg)`,
          }}
        />
      ))}

      {/* Pulsating nebula */}
      <motion.circle
        cx="50%"
        cy="50%"
        r="30%"
        fill="url(#galaxy-gradient)"
        initial={{ opacity: 0.1, scale: 1 }}
        animate={{
          opacity: [0.1, 0.3, 0.1],
          scale: [1, 1.2, 1],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          repeatType: 'reverse',
          ease: 'easeInOut',
        }}
      />
    </svg>
  );
};

export default DynamicBackground;