import React from 'react';
import { motion } from 'framer-motion';
import { Briefcase, Plane, Hotel, Car, Shield, DollarSign, HeadphonesIcon } from 'lucide-react';

const Services = () => {
  const services = [
    {
      icon: Plane,
      title: "Group Flight Bookings",
      description: "Efficient booking of flights for groups of 20 or more, with options for charter flights for larger teams."
    },
    {
      icon: Hotel,
      title: "Corporate Hotel Partnerships",
      description: "Access to our network of business-friendly hotels with negotiated corporate rates and amenities."
    },
    {
      icon: Car,
      title: "Ground Transportation",
      description: "Seamless coordination of ground transport, including private shuttles and car rentals for your team."
    },
    {
      icon: Shield,
      title: "Travel Risk Management",
      description: "Comprehensive risk assessment and management to ensure the safety and security of your traveling employees."
    },
    {
      icon: DollarSign,
      title: "Expense Tracking and Reporting",
      description: "Advanced tools for easy expense management and detailed reporting for better budget control."
    },
    {
      icon: HeadphonesIcon,
      title: "24/7 Travel Support",
      description: "Round-the-clock assistance for any travel-related issues or last-minute changes."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <section className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 py-20">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-6xl mx-auto px-4"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold text-center mb-8 text-white"
        >
          Our Services
        </motion.h1>

        <motion.p
          variants={itemVariants}
          className="text-center text-lg mb-12 text-gray-300"
        >
          At TRIPOSCA, we offer a comprehensive suite of corporate travel services designed to meet the needs of businesses managing group travel.
        </motion.p>

        <motion.div 
          variants={containerVariants}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="bg-gray-900 p-6 rounded-xl shadow-2xl hover:shadow-3xl transition-shadow duration-300"
            >
              <service.icon className="text-blue-500 mb-4" size={32} />
              <h2 className="text-2xl font-semibold mb-2 text-white">{service.title}</h2>
              <p className="text-gray-400">{service.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Services;