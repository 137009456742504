import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Eye, Users, Lock, AlertTriangle, Mail } from 'lucide-react';

const Privacy = () => {
  const sections = [
    {
      icon: Eye,
      title: "Information We Collect",
      content: "We collect information that you provide directly to us, such as your name, email address, phone number, and travel preferences when you book our services or create an account. We may also collect information about your use of our services and website."
    },
    {
      icon: Users,
      title: "How We Use Your Information",
      content: "We use the information we collect to provide, maintain, and improve our services, to process your transactions, and to communicate with you about your bookings and our services. We may also use your information to personalize your experience and to send you promotional communications."
    },
    {
      icon: Shield,
      title: "Data Sharing and Disclosure",
      content: "We may share your information with third-party service providers who perform services on our behalf, such as payment processing and customer service. We do not sell your personal information to third parties. We may disclose your information if required by law or to protect our rights or the rights of others."
    },
    {
      icon: Lock,
      title: "Data Security",
      content: "We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage. However, no method of transmission over the Internet or electronic storage is 100% secure."
    },
    {
      icon: AlertTriangle,
      title: "Your Rights",
      content: "You have the right to access, correct, or delete your personal information. You may also have the right to restrict or object to certain processing of your data. To exercise these rights, please contact us using the information provided below."
    },
    {
      icon: Mail,
      title: "Contact Us",
      content: "If you have any questions or concerns about our Privacy Policy or data practices, please contact us at contact@triposca.in."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <section className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 py-20">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-4xl mx-auto px-4"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold text-center mb-8 text-white"
        >
          Privacy Policy
        </motion.h1>

        <motion.p
          variants={itemVariants}
          className="text-center text-lg mb-12 text-gray-300"
        >
          At TRIPOSCA, we are committed to protecting your privacy and ensuring the security of your personal information.
        </motion.p>

        <motion.div 
          variants={containerVariants}
          className="space-y-8"
        >
          {sections.map((section, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="bg-gray-900 rounded-xl shadow-2xl overflow-hidden hover:shadow-3xl transition-shadow duration-300"
            >
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <section.icon className="text-blue-400 mr-3" size={24} />
                  <h2 className="text-2xl font-semibold text-white">{section.title}</h2>
                </div>
                <p className="text-gray-400">{section.content}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <motion.p
          variants={itemVariants}
          className="text-center text-sm mt-12 text-gray-400"
        >
          This privacy policy was last updated on September 24, 2024.
        </motion.p>
      </motion.div>
    </section>
  );
};

export default Privacy;