import React from 'react';
import { motion } from 'framer-motion';
import { Cookie, Shield, Cog, ToggleLeft, AlertCircle, ExternalLink } from 'lucide-react';

const Cookies = () => {
  const sections = [
    {
      icon: Cookie,
      title: "What Are Cookies",
      content: "Cookies are small text files that are placed on your computer or mobile device when you visit a website. They are widely used to make websites work more efficiently and provide a better user experience."
    },
    {
      icon: Cog,
      title: "How We Use Cookies",
      content: "We use cookies to understand how you use our website, remember your preferences, and improve our services. This includes personalizing content, providing social media features, and analyzing our traffic."
    },
    {
      icon: Shield,
      title: "Types of Cookies We Use",
      content: "We use both session cookies, which expire when you close your browser, and persistent cookies, which stay on your device for a set period. We also use first-party cookies, set by us, and third-party cookies from our partners."
    },
    {
      icon: ToggleLeft,
      title: "Managing Cookies",
      content: "Most web browsers allow you to control cookies through their settings. You can usually find these settings in the 'options' or 'preferences' menu of your browser. However, limiting the ability of websites to set cookies may affect your overall user experience."
    },
    {
      icon: AlertCircle,
      title: "Changes to Our Cookie Policy",
      content: "We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page and updating the 'last updated' date at the bottom of this policy."
    },
    {
      icon: ExternalLink,
      title: "More Information",
      content: "For more information about how we use cookies, please contact us at contact@triposca.in or visit www.allaboutcookies.org to learn more about cookies in general."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <section className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 py-20">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-4xl mx-auto px-4"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold text-center mb-8 text-white"
        >
          Cookie Policy
        </motion.h1>

        <motion.p
          variants={itemVariants}
          className="text-center text-lg mb-12 text-gray-300"
        >
          This policy explains how TRIPOSCA uses cookies on our website.
        </motion.p>

        <motion.div 
          variants={containerVariants}
          className="space-y-8"
        >
          {sections.map((section, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="bg-gray-900 rounded-xl shadow-2xl overflow-hidden hover:shadow-3xl transition-shadow duration-300"
            >
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <section.icon className="text-blue-400 mr-3" size={24} />
                  <h2 className="text-2xl font-semibold text-white">{section.title}</h2>
                </div>
                <p className="text-gray-400">{section.content}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <motion.p
          variants={itemVariants}
          className="text-center text-sm mt-12 text-gray-400"
        >
          This Cookie Policy was last updated on September 24, 2024.
        </motion.p>
      </motion.div>
    </section>
  );
};

export default Cookies;