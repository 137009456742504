import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Mail, Plane, Calendar, MapPin, CreditCard, Users, Luggage } from 'lucide-react';

const BookingForm = () => {
  const [step, setStep] = useState(1);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step < 3) {
      setStep(step + 1);
    } else {
      const formData = new FormData(formRef.current);
      const data = Object.fromEntries(formData.entries());
      console.log(data);
      alert("Booking Submitted!");
    }
  };

  const InputField = ({ icon: Icon, ...props }) => (
    <motion.div 
      className="relative"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
      <input
        {...props}
        className="w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-10 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
    </motion.div>
  );

  const steps = [
    { number: 1, title: "Personal Info" },
    { number: 2, title: "Flight Details" },
    { number: 3, title: "Payment" },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <section className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 py-20">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-4xl mx-auto px-4"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold text-center mb-8 text-white"
        >
          Book Your Flight
        </motion.h1>

        <motion.div 
          variants={itemVariants}
          className="mb-8 flex justify-center"
        >
          {steps.map((s) => (
            <div key={s.number} className="flex items-center">
              <motion.div 
                className={`rounded-full h-10 w-10 flex items-center justify-center ${step >= s.number ? 'bg-blue-500' : 'bg-gray-700'} text-white font-bold`}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
              >
                {s.number}
              </motion.div>
              <div className={`ml-2 mr-8 text-sm ${step >= s.number ? 'text-blue-300' : 'text-gray-400'}`}>
                {s.title}
              </div>
              {s.number < steps.length && (
                <motion.div 
                  className={`h-1 w-8 ${step > s.number ? 'bg-blue-500' : 'bg-gray-700'} mr-8`}
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{ duration: 0.5 }}
                />
              )}
            </div>
          ))}
        </motion.div>

        <motion.div
          variants={itemVariants}
          className="bg-gray-900 p-8 rounded-xl shadow-2xl"
        >
          <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
            <AnimatePresence mode="wait">
              {step === 1 && (
                <motion.div
                  key="step1"
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 50 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                >
                  <div className="space-y-4">
                    <InputField
                      icon={User}
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Full Name"
                      required
                    />
                    <InputField
                      icon={Mail}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                </motion.div>
              )}

              {step === 2 && (
                <motion.div
                  key="step2"
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 50 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                >
                  <div className="space-y-4">
                    <InputField
                      icon={Plane}
                      type="text"
                      id="flight"
                      name="flight"
                      placeholder="Flight Number"
                      required
                    />
                    <InputField
                      icon={Calendar}
                      type="date"
                      id="date"
                      name="date"
                      required
                    />
                    <InputField
                      icon={MapPin}
                      type="text"
                      id="destination"
                      name="destination"
                      placeholder="Destination"
                      required
                    />
                    <div className="grid grid-cols-2 gap-4">
                      <InputField
                        icon={Users}
                        type="number"
                        id="passengers"
                        name="passengers"
                        placeholder="Passengers"
                        required
                      />
                      <InputField
                        icon={Luggage}
                        type="number"
                        id="luggage"
                        name="luggage"
                        placeholder="Luggage (kg)"
                        required
                      />
                    </div>
                  </div>
                </motion.div>
              )}

              {step === 3 && (
                <motion.div
                  key="step3"
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 50 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                >
                  <div className="space-y-4">
                    <InputField
                      icon={CreditCard}
                      type="text"
                      id="cardNumber"
                      name="cardNumber"
                      placeholder="Card Number"
                      required
                    />
                    <div className="grid grid-cols-2 gap-4">
                      <InputField
                        icon={Calendar}
                        type="text"
                        id="cardExpiry"
                        name="cardExpiry"
                        placeholder="MM/YY"
                        required
                      />
                      <InputField
                        icon={CreditCard}
                        type="text"
                        id="cardCVC"
                        name="cardCVC"
                        placeholder="CVC"
                        required
                      />
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <motion.div 
              variants={itemVariants}
              className="flex justify-between"
            >
              {step > 1 && (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={() => setStep(step - 1)}
                  className="bg-gray-700 text-white py-2 px-4 rounded-lg font-semibold shadow-lg hover:bg-gray-600 transition duration-300"
                >
                  Previous
                </motion.button>
              )}
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-4 rounded-lg font-semibold shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 ml-auto"
              >
                {step === 3 ? "Book Now" : "Next"}
              </motion.button>
            </motion.div>
          </form>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default BookingForm;