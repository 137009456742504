import React from 'react';
import { motion } from 'framer-motion';
import { Book, CheckCircle, XCircle, CreditCard, Briefcase, HelpCircle } from 'lucide-react';

const Terms = () => {
  const sections = [
    {
      icon: Book,
      title: "Acceptance of Terms",
      content: "By accessing or using TRIPOSCA's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services."
    },
    {
      icon: CheckCircle,
      title: "Use of Services",
      content: "You may use our services only for lawful purposes and in accordance with these Terms. You agree not to use our services in any way that violates any applicable local, state, national, or international law or regulation."
    },
    {
      icon: Briefcase,
      title: "Corporate Travel Bookings",
      content: "Our services are designed for corporate travel bookings with a minimum group size of 20 persons. By using our services, you confirm that you are authorized to make bookings on behalf of your organization."
    },
    {
      icon: XCircle,
      title: "Cancellation and Refund Policy",
      content: "Cancellation policies may vary depending on the specific travel arrangements. Please review the cancellation policy provided at the time of booking. Refunds, if applicable, will be processed according to our refund policy."
    },
    {
      icon: CreditCard,
      title: "Payments and Fees",
      content: "You agree to pay all fees and charges associated with your bookings. We use secure third-party payment processors to handle transactions. All fees are non-refundable unless otherwise specified in our refund policy."
    },
    {
      icon: HelpCircle,
      title: "Customer Support",
      content: "We provide 24/7 customer support for all your travel-related queries and issues. You can reach our support team through the contact information provided on our website."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <section className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 py-20">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-4xl mx-auto px-4"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold text-center mb-8 text-white"
        >
          Terms of Service
        </motion.h1>

        <motion.p
          variants={itemVariants}
          className="text-center text-lg mb-12 text-gray-300"
        >
          Please read these terms carefully before using TRIPOSCA's services.
        </motion.p>

        <motion.div 
          variants={containerVariants}
          className="space-y-8"
        >
          {sections.map((section, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="bg-gray-900 rounded-xl shadow-2xl overflow-hidden hover:shadow-3xl transition-shadow duration-300"
            >
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <section.icon className="text-blue-400 mr-3" size={24} />
                  <h2 className="text-2xl font-semibold text-white">{section.title}</h2>
                </div>
                <p className="text-gray-400">{section.content}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <motion.p
          variants={itemVariants}
          className="text-center text-sm mt-12 text-gray-400"
        >
          These Terms of Service were last updated on September 24, 2024. We reserve the right to update or change these terms at any time. It is your responsibility to check this page periodically for changes.
        </motion.p>
      </motion.div>
    </section>
  );
};

export default Terms;