import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, FileText, Scale, Globe, Link, RefreshCw } from 'lucide-react';

const Disclaimer = () => {
  const sections = [
    {
      icon: AlertTriangle,
      title: "General Disclaimer",
      content: "The information provided on TRIPOSCA's website and through our services is for general informational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability of the information, products, services, or related graphics contained on the website for any purpose."
    },
    {
      icon: FileText,
      title: "No Legal Advice",
      content: "The content on this website is not intended to be a substitute for professional legal advice. Always seek the advice of a qualified legal professional regarding any questions you may have about legal issues relating to your travel plans or corporate agreements."
    },
    {
      icon: Scale,
      title: "Limitation of Liability",
      content: "In no event will TRIPOSCA be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website or our services."
    },
    {
      icon: Globe,
      title: "External Links",
      content: "Through this website you may be able to link to other websites which are not under the control of TRIPOSCA. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them."
    },
    {
      icon: Link,
      title: "Website Availability",
      content: "Every effort is made to keep the website up and running smoothly. However, TRIPOSCA takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control."
    },
    {
      icon: RefreshCw,
      title: "Changes to Disclaimer",
      content: "TRIPOSCA reserves the right to modify this disclaimer at any time. We encourage visitors to frequently check this page for any changes. Your continued use of this website after any change in this disclaimer will constitute your acceptance of such change."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <section className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 py-20">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-4xl mx-auto px-4"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold text-center mb-8 text-white"
        >
          Disclaimer
        </motion.h1>

        <motion.p
          variants={itemVariants}
          className="text-center text-lg mb-12 text-gray-300"
        >
          Please read this disclaimer carefully before using TRIPOSCA's services or website.
        </motion.p>

        <motion.div 
          variants={containerVariants}
          className="space-y-8"
        >
          {sections.map((section, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="bg-gray-900 rounded-xl shadow-2xl overflow-hidden hover:shadow-3xl transition-shadow duration-300"
            >
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <section.icon className="text-blue-400 mr-3" size={24} />
                  <h2 className="text-2xl font-semibold text-white">{section.title}</h2>
                </div>
                <p className="text-gray-400">{section.content}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <motion.p
          variants={itemVariants}
          className="text-center text-sm mt-12 text-gray-400"
        >
          This disclaimer was last updated on September 24, 2024.
        </motion.p>
      </motion.div>
    </section>
  );
};

export default Disclaimer;