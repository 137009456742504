import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Briefcase, Users, ShieldCheck, Plane, Hotel, Car } from 'lucide-react';
import DynamicBackground from './DynamicBackground';

const HeroSection = () => {
  const navigate = useNavigate();

  const handleExploreClick = () => {
    navigate('/flights');
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 300, damping: 24 }
    }
  };

  return (
    <motion.section
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="relative min-h-screen flex flex-col items-center justify-center overflow-hidden bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 text-white px-4 py-16"
    >
      <DynamicBackground />

      <motion.div 
        variants={itemVariants} 
        className="text-center z-10 mb-12 mt-16 sm:mt-20 md:mt-24 lg:mt-28"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold mb-6 font-sans">Streamline Your Corporate Travel</h1>
        <p className="text-xl sm:text-2xl md:text-3xl mb-8 sm:mb-10 font-light">Efficient solutions for groups of 20 or more employees</p>
      </motion.div>

      <motion.div 
        variants={itemVariants}
        className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-8 max-w-4xl w-full z-10"
      >
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="flex items-center space-x-3 bg-white bg-opacity-20 rounded-lg p-4"
          >
            <Briefcase className="text-yellow-400" size={24} />
            <span>Tailored Corporate Packages</span>
          </motion.div>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="flex items-center space-x-3 bg-white bg-opacity-20 rounded-lg p-4"
          >
            <Users className="text-yellow-400" size={24} />
            <span>Group Booking Discounts</span>
          </motion.div>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="flex items-center space-x-3 bg-white bg-opacity-20 rounded-lg p-4"
          >
            <ShieldCheck className="text-yellow-400" size={24} />
            <span>Travel Risk Management</span>
          </motion.div>
        </div>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 text-gray-900 px-8 py-4 rounded-full font-bold text-lg transition duration-300 shadow-lg"
          onClick={handleExploreClick}
        >
          Book Corporate Travel Now
        </motion.button>
      </motion.div>

      <motion.div 
        variants={itemVariants}
        className="mt-12 text-center z-10"
      >
        <p className="text-lg mb-4">Trusted by leading corporations worldwide</p>
        <div className="flex justify-center space-x-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <motion.svg
              key={star}
              whileHover={{ scale: 1.2, rotate: 20 }}
              className="w-6 h-6 text-yellow-400 fill-current"
              viewBox="0 0 24 24"
            >
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </motion.svg>
          ))}
        </div>
        <p className="text-sm mt-2">4.9 out of 5 based on 1,000+ corporate client reviews</p>
      </motion.div>

      <motion.div 
        variants={itemVariants}
        className="mt-16 w-full max-w-6xl z-10"
      >
        <h2 className="text-3xl font-bold mb-8 text-center">Our Corporate Travel Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-6"
          >
            <Plane className="text-yellow-400 mb-4" size={40} />
            <h3 className="text-xl font-semibold mb-2">Flight Bookings</h3>
            <p>Seamless group flight reservations with preferential rates and flexible booking options.</p>
          </motion.div>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-6"
          >
            <Hotel className="text-yellow-400 mb-4" size={40} />
            <h3 className="text-xl font-semibold mb-2">Hotel Accommodations</h3>
            <p>Exclusive corporate rates at premium hotels worldwide, ensuring comfort and convenience.</p>
          </motion.div>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-6"
          >
            <Car className="text-yellow-400 mb-4" size={40} />
            <h3 className="text-xl font-semibold mb-2">Transportation</h3>
            <p>Efficient ground transportation solutions, from car rentals to private transfers.</p>
          </motion.div>
        </div>
      </motion.div>

      <motion.div 
        variants={itemVariants}
        className="mt-16 w-full max-w-4xl z-10"
      >
        <h2 className="text-3xl font-bold mb-8 text-center">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-6">
            <h3 className="text-xl font-semibold mb-4">24/7 Support</h3>
            <p>Our dedicated team is available round the clock to assist with any travel-related queries or emergencies.</p>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-6">
            <h3 className="text-xl font-semibold mb-4">Customized Solutions</h3>
            <p>Tailored travel packages designed to meet your specific corporate requirements and preferences.</p>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-6">
            <h3 className="text-xl font-semibold mb-4">Cost Savings</h3>
            <p>Benefit from our negotiated rates and cost-effective travel management strategies.</p>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-6">
            <h3 className="text-xl font-semibold mb-4">Reporting & Analytics</h3>
            <p>Comprehensive travel spend reports and insights to help optimize your corporate travel program.</p>
          </div>
        </div>
      </motion.div>

      <motion.div 
        variants={itemVariants}
        className="mt-16 text-center z-10"
      >
        <h2 className="text-3xl font-bold mb-4">Ready to Streamline Your Corporate Travel?</h2>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-gradient-to-r from-yellow-400 to-orange-500 text-gray-900 px-8 py-4 rounded-full font-bold text-lg transition duration-300 shadow-lg"
          onClick={() => navigate('/contact')}
        >
          Contact Our Team
        </motion.button>
      </motion.div>
    </motion.section>
  );
};

export default HeroSection;