import React from 'react';
import { motion } from 'framer-motion';
import { Briefcase, Plane, Shield, Clock } from 'lucide-react';

const AboutUs = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const features = [
    { icon: Briefcase, label: 'Tailored Corporate Packages' },
    { icon: Plane, label: 'Group Booking Discounts' },
    { icon: Shield, label: 'Travel Risk Management' },
    { icon: Clock, label: '24/7 Corporate Support' }
  ];

  return (
    <section className="py-20 bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 text-white">
      <motion.div
        className="container mx-auto px-4"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h2 
          className="text-4xl md:text-5xl text-center font-bold mb-12 text-white"
          variants={itemVariants}
        >
          About TRIPOSCA Corporate Travel
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-16">
          <motion.div variants={itemVariants}>
            <h3 className="text-2xl font-semibold mb-4 text-yellow-400">Our Mission</h3>
            <p className="text-lg text-gray-300 leading-relaxed">
              At TRIPOSCA, we're dedicated to streamlining corporate travel for businesses of all sizes. Our mission is to provide efficient, cost-effective, and hassle-free travel solutions for companies booking trips for 20 or more employees. We understand the unique challenges of managing corporate travel, and we're here to make it simple and rewarding.
            </p>
          </motion.div>
          <motion.div 
            className="relative h-80 rounded-lg overflow-hidden shadow-xl"
            variants={itemVariants}
          >
            <img 
              src="about-us-image.jpg" 
              alt="Corporate travel" 
              className="object-cover w-full h-full"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/api/placeholder/800/600";
              }}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
          </motion.div>
        </div>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16"
          variants={containerVariants}
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="bg-white bg-opacity-10 backdrop-blur-lg p-6 rounded-lg shadow-lg text-center"
              variants={itemVariants}
            >
              <feature.icon className="mx-auto mb-4 text-yellow-400" size={32} />
              <p className="text-lg text-gray-300">{feature.label}</p>
            </motion.div>
          ))}
        </motion.div>

        <motion.div variants={itemVariants}>
          <h3 className="text-2xl font-semibold mb-4 text-yellow-400">Our Commitment to Corporate Clients</h3>
          <p className="text-lg text-gray-300 leading-relaxed mb-6">
            We understand that business travel is about more than just getting from point A to point B. It's about productivity, efficiency, and maintaining your company's standards. That's why we're committed to:
          </p>
          <ul className="list-disc list-inside text-lg text-gray-300 leading-relaxed mb-6 space-y-2">
            <li>Offering competitive rates for group bookings of 20 or more</li>
            <li>Providing dedicated account managers for personalized service</li>
            <li>Ensuring duty of care with comprehensive travel risk management</li>
            <li>Delivering detailed reporting for better travel spend visibility</li>
            <li>Offering flexible booking options to accommodate last-minute changes</li>
          </ul>
        </motion.div>

        <motion.div 
          className="bg-white bg-opacity-10 backdrop-blur-lg p-8 rounded-lg shadow-xl mt-16"
          variants={itemVariants}
        >
          <h3 className="text-2xl font-semibold mb-4 text-yellow-400">Contact Us</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h4 className="text-xl font-semibold mb-2 text-white">Directors</h4>
              <p className="text-lg text-gray-300">Akash Rathod</p>
              <p className="text-lg text-gray-300">Ritesh Goswami</p>
            </div>
            <div>
              <h4 className="text-xl font-semibold mb-2 text-white">Phone Numbers</h4>
              <p className="text-lg text-gray-300">+918866858559</p>
              <p className="text-lg text-gray-300">+919737445544</p>
            </div>
          </div>
          <div className="mt-6">
            <h4 className="text-xl font-semibold mb-2 text-white">Address</h4>
            <p className="text-lg text-gray-300">
              690/4531 Bapunagar, Rakhial Rd, Bapunagar, Ahmedabad, Ahmedabad City, Gujarat, India, 380024
            </p>
          </div>
          <div className="mt-6">
            <h4 className="text-xl font-semibold mb-2 text-white">Company Information</h4>
            <p className="text-lg text-gray-300">
              TRIPOSCA PVT LTD was established on July 17, 2023 in Ahmedabad, Gujarat. Founded with a vision to revolutionize corporate travel, our company was born out of the ambition to make travel easy, efficient, and hassle-free for the corporate sector. We recognized the unique challenges faced by businesses when organizing travel for large groups and set out to create tailored solutions that cater specifically to these needs. Our local roots in Ahmedabad, combined with our global outlook, position us uniquely to understand and serve the diverse requirements of corporate travelers, both within India and internationally.
            </p>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default AboutUs;