import React from 'react';
import { motion } from 'framer-motion';
import { MapPin, Calendar, Star } from 'lucide-react';

const destinations = [
  { id: 1, name: 'Paris', image: '/paris.jpg', country: 'France', rating: 4.8, bestTime: 'April - October', description: 'The City of Light beckons with its iconic Eiffel Tower, world-class cuisine, and timeless art.' },
  { id: 2, name: 'Tokyo', image: '/tokyo.jpg', country: 'Japan', rating: 4.7, bestTime: 'March - May', description: 'A dazzling blend of ultra-modern and traditional, Tokyo offers an unforgettable urban adventure.' },
  { id: 3, name: 'New York', image: '/new-york.jpg', country: 'USA', rating: 4.6, bestTime: 'April - June', description: 'The Big Apple never sleeps, offering world-famous attractions, diverse neighborhoods, and endless energy.' },
  { id: 4, name: 'Sydney', image: '/sydney.jpg', country: 'Australia', rating: 4.5, bestTime: 'September - November', description: 'Stunning harbors, golden beaches, and the iconic Opera House make Sydney a must-visit destination.' },
];

const FeaturedDestinations = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <section className="py-16 bg-gradient-to-br from-gray-900 to-blue-900">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold text-center mb-12 text-white"
        >
          Discover Dream Destinations
        </motion.h2>
        <motion.div 
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
        >
          {destinations.map((destination) => (
            <motion.div
              key={destination.id}
              variants={cardVariants}
              className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 transform hover:-translate-y-2"
            >
              <div className="relative">
                <img src={destination.image} alt={destination.name} className="w-full h-48 object-cover" />
                <div className="absolute top-0 left-0 bg-blue-600 text-white px-2 py-1 rounded-br-lg">
                  <Star className="inline-block w-4 h-4 mr-1" />
                  {destination.rating}
                </div>
              </div>
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2 text-white">{destination.name}</h3>
                <p className="text-gray-400 mb-2"><MapPin className="inline-block w-4 h-4 mr-1" />{destination.country}</p>
                <p className="text-gray-400 mb-2"><Calendar className="inline-block w-4 h-4 mr-1" />Best time: {destination.bestTime}</p>
                <p className="text-gray-300 mb-4 h-20 overflow-hidden">{destination.description}</p>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-4 rounded-md hover:from-blue-600 hover:to-purple-700 transition duration-300"
                >
                  Explore {destination.name}
                </motion.button>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default FeaturedDestinations;