import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import IntroAnimation from '../components/IntroAnimation';
import HeroSection from '../components/HeroSection';
import FeaturedDestinations from '../components/FeaturedDestinations';
import FlightSearchForm from './FlightsPage';
import { Users, TrendingUp, Shield, HeadphonesIcon } from 'lucide-react';

const HomePage = () => {
  const [introComplete, setIntroComplete] = useState(false);

  const features = [
    { icon: Users, title: "Group Bookings", description: "Effortless bookings for 20+ travelers" },
    { icon: TrendingUp, title: "Cost Savings", description: "Exclusive corporate rates and discounts" },
    { icon: Shield, title: "Travel Risk Management", description: "Ensure employee safety and security" },
    { icon: HeadphonesIcon, title: "24/7 Support", description: "Round-the-clock assistance for your team" },
  ];

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <>
      <IntroAnimation onComplete={() => setIntroComplete(true)} />
      <AnimatePresence>
        {introComplete && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <HeroSection />
            <FlightSearchForm />
            <FeaturedDestinations />

            
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default HomePage;